<script setup lang="ts">
import ArrowLeftIcon from '@/static/icons/arrow-left.svg'

const props = defineProps<{
  title: string,
  description: string,
  buttonText: string,
  isLoading?: boolean,
  showGoBackBtn?: boolean,
}>()

const emit = defineEmits(['buttonClicked', 'backButtonClicked'])

</script>

<template lang="pug">
.auth-wrap
  form.auth-content(@submit.prevent="emit('buttonClicked')")
    .title
      Transition(name="prev-btn" appear)
        .title__prev-btn(v-if="props.showGoBackBtn" @click="props.showGoBackBtn && emit('backButtonClicked')") 
          ArrowLeftIcon.icon
      .title__text {{ props.title }}
    .description(v-if="props.description") {{ props.description }}
    .main
      slot
    UiButton.main-button(type="submit" size="md" :loading="props.isLoading") {{ props.buttonText }}
    .footer(v-if="$slots.footer")
      slot(name="footer")
</template>

<style lang="scss" scoped>
@keyframes show-back-button {
  0% {
    opacity: 0;
    max-width: 0%;
    margin-right: 0;
  }
  80% {
    opacity: 0;
    max-width: 20%;
  }
  100% {
    opacity: 1;
    max-width: 100%;
  }
}

.prev-btn-enter-active {
  animation: show-back-button 0.5s;
}
.prev-btn-leave-active {
  animation: show-back-button 0.5s reverse;
}

.auth-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding-left: get-vw(59);
  padding-right: get-vw(59);

  .auth-content {
    display: flex;
    flex-direction: column;
  }
  .title {
    @include headline-h3-h3;
    margin-bottom: get-vw(64);
    display: flex;

    &__prev-btn {
      
      margin-right: 0;
      height: get-vw(52);
      margin-right: get-vw(12);
      width: get-vw(52);
      cursor: pointer;

      .icon {
        width: 100%;
        height: 100%;
      }
    }

    &__text {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .description {
    @include text-t4-regular;
    margin-bottom: get-vw(36);
  }

  .main-button {
    margin-bottom: get-vw(24);
  }
}

@include tablet {
  .auth-wrap {
    padding-left: get-vw(32, "md");
    padding-right: get-vw(15, "md");
    margin-top: get-vw(-127, "md");

    .title {
      @include headline-h3-m;
      margin-bottom: get-vw(27, "md");
      &__prev-btn {
        margin-right: get-vw(8, "md");
        width: get-vw(23, "md");
        height: get-vw(23, "md");
      }
    }
    .description {
      @include text-t4-regular-m;
      margin-bottom: get-vw(45, "md");
    }
    .main-button {
      margin-bottom: get-vw(20, "md");
    }
  }
}

@include mobile {
  .auth-wrap {
    width: 100vw;
    padding-left: 0;
    padding-top: get-vw(19, "sm");
    padding-bottom: get-vw(28, "sm");
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;

    .auth-content {
      height: 100%;
      padding-left: get-vw(53, "sm");
      padding-right: get-vw(53, "sm");
    }

    .title {
      @include headline-h3-m('sm');
      margin-right: auto;
      margin-top: get-vw(48, "sm");
      &__prev-btn {
        margin-right: get-vw(8, "sm");
        width: get-vw(23, "sm");
        height: get-vw(23, "sm");
      }
    }

    .description {
      @include text-t4-regular-m('sm');
      margin-right: auto;
      margin-top: get-vw(24, "sm");
      width: get-vw(265, "sm");
    }

    .main {
      margin-left: auto;
      margin-right: auto;
      margin-top: get-vw(36, "sm");
      max-width: 100%;
    }

    .main-button {
      margin-top: get-vw(8, 'sm');
      margin-bottom: 0;
    }

    .footer {
      margin-top: get-vw(36, 'sm');
    }
  }
}
</style>