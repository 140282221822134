<script setup lang="ts">
import Fire from '@/assets/svg/fire.svg'
import FireMobile from '@/assets/svg/fireMobile.svg'

const mainStore = useMainStore()
const modalRef = ref(null);

const closeModal = () => {
  modalRef.value?.onClose()
}

const onAdditionalButtonClick = () => {
  mainStore.setHotContentEnabled()
  closeModal()
}
</script>

<template lang="pug">
.hot-content
  button.hot-button(v-modal-open="'modalRef'")
    Fire.ice-cream.mx-auto
  .modal
    ui-modal(
      ref="modalRef"
      title="Внимание: контент для взрослых 🔞"
      :showAdditionalButton="true"
      :showTwoAdditionalButton="false"
      additionalButtonLabel="Отказаться"
      additionalButtonColor="warning"
      mainButtonLabel="Мне есть 18"
      mainButtonColor="default"
      @mainButtonClick="closeModal"
      @additionalButtonClick="onAdditionalButtonClick"
    )
      .hot-description В этом режиме доступны статьи, содержащие материалы для взрослых, подтвердите, что вам уже исполнилось 18 лет.
</template>

<style lang="scss" scoped>
.modal {
  bottom: 40%!important;
}
.hot-button {
  border: none;
  background-color: transparent;
  padding: 0;
}
.ice-cream {
  width: 100%;
  cursor: pointer;
}

.hot-description {
  @include text-t4-regular;
  margin-bottom: get-vw(41);
}
@include tablet {
  .hot-description {
    @include text-t4-regular-m;
    margin-bottom: get-vw(24, "md");
  }

  .ice-cream {
    width: get-vw(40, "md");
    height: get-vw(40, "md");
  }
}

@include mobile {
  .hot-content {
    display: flex;
  }
  .hot-button {
    width: get-vw(32, "sm");
    height: get-vw(32, "sm");
    padding-left: get-vw(7, "sm");
    margin-top: get-vw(-1, "sm");
    margin-top: auto;
    margin-bottom: auto;
  }
  .ice-cream {
    width: 100%;
    height: 100%;
  }

  .hot-description {
    font-size: get-vw(16, "sm");
    font-weight: 400;
    line-height: get-vw(18.78, "sm");
    margin-bottom: get-vw(24, "sm");
  }

  svg {
    &:deep(path) {
      stroke-width: 2;
    }
  }
  
}
</style>