import { defineStore } from "pinia"
import { useCategoriesStore } from '@/stores/categories'

import type { 
  CategoryData,
  CategoryGroup
} from '@/stores/categories'

export type MenuCategory = {
  id: string;
  label: string;
  categories: CategoryData[];
  showSubMenu: boolean;
}

type MenuStore = {
  menu: MenuCategory[];
}

export const useMenuStore = defineStore('menu', {
  state: (): MenuStore => ({
    menu: []
  }),
  actions: {
    initializeMenu() {
      const categoriesStore = useCategoriesStore()

      this.menu = categoriesStore.categoryGroups.map((categoryGroup: CategoryGroup) => ({
        ...categoryGroup,
        showSubMenu: false
      }))
    }
  }
})
