export const NAMES = [
  'Милый',
  'Ласковый',
  'Заботливый',
  'Честный',
  'Приветливый',
  'Верный',
  'Незаменимый',
  'Сильный',
  'Умный',
  'Сексапильный',
  'Умопомрачительный',
  'Королевский',
  'Бодрый',
  'Всезнающий',
  'Коварный',
  'Модный',
  'Няшный',
  'Пушистый',
  'Сексуальный',
  'Желанный',
  'Шикарный',
  'Веселый',
  'Гордый',
  'Добролюбивый',
  'Искренний',
  'Головокружительный',
  'Бесподобный',
  'Беспринципиальный',
  'Дорогой',
  'Значимый',
  'Незаменимый',
  'Миролюбивый',
  'Непредсказуемый',
  'Соблазнительный',
  'Гордый',
  'Волшебный',
  'Божественный',
  'Привлекательный',
  'Стильный',
  'Классный',
  'Правильный',
  'Заботливый',
  'Загадочный',
  'Напористый',
  'Добрый',
  'Любезный',
  'Удачливый',
  'Чуткий',
  'Бесценный',
  'Остроумный',
  'Гениальный',
  'Открытый',
  'Грациозный',
  'Спортивный',
  'Вежливый',
  'Хозяйственный',
  'Галантный',
  'Ловкий',
  'Любопытный',
  'Неприступный',
  'Могучий',
  'Неимоверный',
  'Деловой',
  'Открытый',
  'Жгучий',
  'Неземной',
  'Бесценный',
  'Видный',
  'Ароматный',
  'Горячий',
  'Гламурный',
  'Забавный',
  'Золотой',
  'Звездный',
  'Искренний',
  'Искрометный',
  'Медовый',
  'Модняшка',
  'Неординарный',
  'Няшный',
  'Обаятельный',
  'Огненный',
  'Прилежный',
  'Праздничный',
  'Ритмичный',
  'Стройный',
  'Терпеливый',
  'Хозяйственный',
  'Экстравагантный'
]

export const SURNAMES = [
  'осел',
  'козел',
  'кролик',
  'трубкозуб',
  'альбатрос',
  'аллигатор',
  'удильщик',
  'муравей',
  'муравьед',
  'жерех',
  'бабуин',
  'барсук',
  'бандикут',
  'василиск',
  'бобр',
  'клоп',
  'жук',
  'бизон',
  'удав',
  'кабан',
  'бонобо',
  'бовид',
  'верблюд',
  'кардинал',
  'карп',
  'сом',
  'моллюск',
  'хамелеон',
  'гепард',
  'шимпанзе',
  'бурундук',
  'таракан',
  'кондор',
  'констриктор',
  'койот',
  'краб',
  'кран',
  'рак',
  'крикет',
  'крокодил',
  'олень',
  'динго',
  'динозавр',
  'дельфин',
  'голубь',
  'дракон',
  'орел',
  'червь',
  'угорь',
  'цапля',
  'слон',
  'лось',
  'эму',
  'горностай',
  'сокол',
  'хорек',
  'зяблик',
  'светляк',
  'фламинго',
  'геккон',
  'гиббон',
  'жираф',
  'гусь',
  'суслик',
  'кузнечик',
  'рябчик',
  'палтус',
  'хомяк',
  'заяц',
  'лунь',
  'ястреб',
  'ежик',
  'бегемот',
  'анкилостом',
  'шершень',
  'колибри',
  'шакал',
  'ягуар',
  'кенгуру',
  'зимородок',
  'змей',
  'киви',
  'криль',
  'жаворонок',
  'лемминг',
  'лемур',
  'леопард',
  'леопон',
  'лимпет',
  'лев',
  'омар',
  'ламантин',
  'мандрил',
  'марлин',
  'сурок',
  'мастодонт',
  'медоуларк',
  'сурикат',
  'пескарь',
  'клещ',
  'пересмешник',
  'моль',
  'мангуст',
  'комар',
  'мул',
  'овцебык',
  'нарвал',
  'тритон',
  'соловей',
  'оцелот',
  'осьминог',
  'опоссум',
  'орангутан',
  'страус',
  'бык',
  'попугай',
  'павлин',
  'пеликан',
  'пингвин',
  'окунь',
  'фазан',
  'планарий',
  'утконос',
  'пони',
  'дикобраз',
  'примас',
  'тупик',
  'питон',
  'енот',
  'ворон',
  'носорог',
  'грызун',
  'ладья',
  'петух',
  'парусник',
  'лосось',
  'гребешок',
  'скорпион',
  'шелкопряд',
  'сцинк',
  'скунс',
  'слизняк',
  'бекас',
  'воробей',
  'паук',
  'кальмар',
  'скат',
  'аист',
  'осетр',
  'лебедь',
  'тар',
  'такин',
  'тапир',
  'тарантул',
  'долгопят',
  'термит',
  'дрозд',
  'тигр',
  'тиглон',
  'тукан',
  'форель',
  'тунец',
  'индюк',
  'тиранозавр',
  'уриал',
  'гриф',
  'валлаби',
  'морж',
  'кит',
  'сиг',
  'волк',
  'вомбат',
  'дятел',
  'крапивник',
  'як',
  'гаял'
]
