<script setup lang="ts">
import AuthorizationLogin from '@/components/AuthorizationLogin.vue'
import AuthorizationCodeConfirmation from '@/components/AuthorizationCodeConfirmation.vue'
import AuthorizationConfirm from '@/components/AuthorizationConfirm.vue'

import { useUserStore } from '@/stores/user'
import { useMainStore } from '@/stores/index'

import { useListen } from '~/composables/useEventBus'
import { useAuthorization } from '@/composables/useAuthorization'

import { NAMES, SURNAMES } from './constants'

const { login } = useOAuth()

enum States {
  Login = "login",
  LoginConfirmation = "login-confirmation",
  RegisterConfirmation = "register-confirmation",
  SuccesfullyRegistered = "confirm"
}

export type CodeConfirmationState = States.LoginConfirmation | States.RegisterConfirmation

let expiresInInterval: null | ReturnType<typeof setInterval> = null

const setExpiresIn = (targetState: CodeConfirmationState, expiresIn: number) => {
  if (expiresInInterval) {
    clearInterval(expiresInInterval)
  }
  binds.value[targetState].expiresIn = expiresIn
  expiresInInterval = setInterval(() => {
    binds.value[targetState].expiresIn -= 1
    if (binds.value[targetState].expiresIn == 0 && expiresInInterval) {
      clearInterval(expiresInInterval)
    }
  }, 1000)
}

const { authorize, sendCode, sendNewCode } = useAuthorization(setExpiresIn)

const state = ref(States.Login)
const components = {
  [States.Login]: AuthorizationLogin,
  [States.LoginConfirmation]: AuthorizationCodeConfirmation,
  [States.RegisterConfirmation]: AuthorizationCodeConfirmation,
  [States.SuccesfullyRegistered]: AuthorizationConfirm,
}

const component = shallowRef<InstanceType<typeof components[keyof typeof components]>>()

const focus = () => {
  if (state.value === States.Login && component.value) {
    (component.value as InstanceType<typeof components[States.Login]>).focus()
  }
}

const opened = ref(false)
watch(opened, (value) => {
  if (state.value === States.Login && value) {
    (component.value as InstanceType<typeof components[States.Login]>).focus()
  }
})

const mainStore = useMainStore()

const authStore = useAuthStore()

watch(state, (value) => {
  authStore.isLoading = false
})

const open = () => {
  opened.value = true
}

const close = () => {
  // mainStore.closeAuthForm()
  opened.value = false
}

const userStore = useUserStore()

const binds = ref({
  [States.Login]: {
    isRegistration: false,
  },
  [States.LoginConfirmation]: {
    expiresIn: 0,
    error: false,
    phone: ''
  },
  [States.RegisterConfirmation]: {
    expiresIn: 0,
    isRegistration: true,
    error: false,
    phone: ''
  },
  [States.SuccesfullyRegistered]: {
    nickname: '',    
  }
})

//TODO удалить, не понадобится после подключения vkid
// const { afterOAuth } = storeToRefs(mainStore)
// if (afterOAuth?.value) {
//   userStore.updateStore()
  
//   if (userStore.phone && userStore.phoneVerified) {
//     state.value = States.Register
//     mainStore.setAfterOAuth(null)
//   } else {
//     binds.value[States.Login].isRegistration = true
//     state.value = States.Login
//   }
// }

const handlers = {
  [States.Login]:{
    codeSent: async (tel: string) => {
      const phone = tel.replace('+', '')
      userStore.temporarlyPhone = phone

      const { expiresIn, isRegistered } = await sendCode(phone)
      console.log('expiresIn', expiresIn)
      if (isRegistered) {
        userStore.registrationComplete = true
        setExpiresIn(States.LoginConfirmation, expiresIn)
        binds.value[States.LoginConfirmation].phone = phone
        state.value = States.LoginConfirmation
      } else {
        userStore.registrationComplete = false
        setExpiresIn(States.RegisterConfirmation, expiresIn)
        binds.value[States.RegisterConfirmation].phone = phone
        state.value = States.RegisterConfirmation
      }
    },
    socialLogin: (provider: string) => {
      login(provider)
    },
  },
  [States.LoginConfirmation]: {
    resend: async () => {
      console.log('resendCode')
      binds.value[States.LoginConfirmation].error = false
      await sendNewCode(States.LoginConfirmation)
    },
    confirm: async (code: string) => {
      try {
        await authorize(code)
      } catch (e) {
        binds.value[States.LoginConfirmation].error = e.message
        return
      }
      if (!userStore.registrationComplete) {
        await userStore.updateUser({
          registrationComplete: true,
          policyAccepted: true,
        })
      }
      close()
    },
    back: () => {
      state.value = States.Login
    }
  },
  [States.RegisterConfirmation]: {
    resend: async () => {
      console.log('resendCode')
      binds.value[States.RegisterConfirmation].error = false
      await sendNewCode(States.RegisterConfirmation)
    },
    confirm: async (code: string, recieveNews: boolean, policyAgreement: boolean) => {
      if (!policyAgreement) {
        throw new Error('Policy agreement is not set')
      }
      try {
        await authorize(code)
      } catch (e) {
        binds.value[States.RegisterConfirmation].error = e.message
        return
      }

      //generate random name and surname
      
      const randomName = NAMES[Math.floor(Math.random() * NAMES.length)]
      const randomSurname = SURNAMES[Math.floor(Math.random() * SURNAMES.length)]

      userStore.updateUser({
        recieveNews,
        registrationComplete: false,
        policyAccepted: true,
        name: randomName,
        surname: randomSurname
      })
      binds.value[States.SuccesfullyRegistered].nickname = userStore.name + ' ' + userStore.surname
      state.value = States.SuccesfullyRegistered
    },
    back: () => {
      state.value = States.Login
    }
  },
  [States.SuccesfullyRegistered]: {
    confirm: async () => {
      await userStore.updateUser({
        registrationComplete: true,
      })
    }
  }
}

defineExpose({
  open,
  close,
  focus
})
</script>

<template lang="pug">
keep-alive
  component(:is="components[state]" v-bind="binds[state]" v-on="handlers[state]" ref="component")
</template>