<template lang="pug">
.container-menu
  ul.ul-container
    li.menu-item-li(v-for='(item, index) in menuItems' :key='index')
      button.menu-item(@click='toggleSubMenu(index)')
        | {{ item.label }}
        span(v-show='item.showSubMenu')
        span(v-show='!item.showSubMenu')
      transition(name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave")
        ul.ul(v-show='item.showSubMenu')
          li.li(v-for='(category, subIndex) in item.categories' :key='subIndex')
            .icon-wrap
              ui-icon(:icon="categoryIcon(category)" isCategoryIcon)
            NuxtLink(:to='`/communities/${category.id}/${firstSubCategoryId(category) || ""}`') {{ category.attributes.label }}
</template>

<script>
import { useMenuStore } from '@/stores/menu'

export default {
  name: 'AppMenu',
  computed: {
    menuItems () {
      return useMenuStore().menu
    },
    categoryIcon() {
      return category => {
        if (category?.attributes.icon?.data?.attributes?.name)
          return category?.attributes.icon?.data.attributes.name

        return 'GenderPersp'
      }
    },
    firstSubCategoryId() {
      return category => category.attributes.sub_categories.data[0]?.id
    }
  },
  methods: {
    toggleSubMenu (index) {
      this.menuItems.forEach((item, i) => {
        if (i !== index) {
          item.showSubMenu = false
        } else {
          item.showSubMenu = !item.showSubMenu
        }
      })
    },
    enter (el) {
      el.style.height = 'auto'
      const endHeight = window.getComputedStyle(el).height
      el.style.height = '0px'
      window.getComputedStyle(el)
      
      setTimeout(() => {
        el.style.height = endHeight
      }); 
    },
    afterEnter(el) {
      el.style.height = 'auto';
    },
    leave(el) {
        /*
          Same as with the enter method, but only the other way around.
        */
        el.style.height = window.getComputedStyle(el).height;
        
        window.getComputedStyle(el);

        setTimeout(() => {
          el.style.height = 0;
        });
      }
  }
}

</script>

<style lang="scss" scoped>
.container-menu {
  /* margin-left: get-vw(115); */
  margin-top: get-vw(16);
  margin-right: get-vw(20);
}
.ul-container {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}
.ul {
  @include text-t8-regular;
  color: $black;
  list-style-type: none;
  padding: 0;
  /* margin-top: get-vw(40); */
}
.li {
  align-items: center;
  display: flex;
  margin-bottom: get-vw(35);
  cursor: pointer;
  &:first-child {
    padding-top: get-vw(40);
  }
  &:last-child {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    color: $black;
  }
  .icon:deep(path) {
    stroke: $creme-brulee;
    &[fill] {
      fill: $creme-brulee;
    }
  }
}
.menu-item-li {
  padding-top: get-vw(35);
  padding-bottom: get-vw(1);
  overflow: hidden;
}
.menu-item {
  @include text-t8-regular ;
  color: $black;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.expand-enter-active, .expand-leave-active {
  transition: height .5s ease-in-out;
}

.icon-wrap {
  margin-right: get-vw(15);

  &:deep(svg) {
    height: get-vw(56);
    width: get-vw(56);
  }
  &:deep(svg g) {
    clip-path: none;
  }
}

@include tablet {
  .container-menu {
    margin-left: get-vw(85, "md");
    margin-top: get-vw(10, "md")
  }
  .menu-item {
    @include text-t8-semibold-m;
  }
  .menu-item-li {
    padding-top: get-vw(24, "md");
  }
  .ul-container {
    margin: 0;
  }
  .ul {
    @include text-t8-medium-m;
  }
  .li {
    align-items: start;
    margin-bottom: get-vw(24, "md");
    margin-left: get-vw(8, "md");
    &:first-child {
      padding-top: get-vw(24, "md");
    }

    a {
      padding-top: get-vw(4, 'md');
    }
  }
  .icon-wrap {
    align-items: center;
    display: flex;
    height: get-vw(32, "md");
    justify-content: center;
    margin-right: get-vw(15, "md");
    width: get-vw(32, "md");

    &:deep(svg) {
      height: get-vw(32, "md");
      width: get-vw(32, "md");
    }
    &:deep(svg g) {
    clip-path: none;
    }
  }
}

@include mobile {
  .container-menu {
    margin-left: get-vw(16, "sm");
    margin-right: get-vw(16, "sm");
    margin-top: -6.8vw
  }
  .menu-item {
    @include text-t4-medium-m("sm");
  }
  .menu-item-li {
    padding-top: get-vw(24, "sm");
  }
  .ul {
    @include text-t4-semibold-m("sm");
  }
  .li {
    align-items: center;
    margin-left: 0;
    &:first-child {
      padding-top: get-vw(24, "sm");
    }
  }
  .icon-wrap {
    margin-right: get-vw(8, "sm");
    height: get-vw(32, "sm");
    width: get-vw(32, "sm");
    &:deep(svg) {
      height: get-vw(32, "sm");
      width: get-vw(32, "sm");
    }
  }
}
</style>
