<script setup lang="ts">
import { storeToRefs } from 'pinia'
import Menu from '@/assets/svg/menu.svg'
import CloseIcon from '@/assets/svg/icon-x.svg'

const mainStore = useMainStore()
const { toggleMenu } = useMainStore()
const { isMenuOpen } = storeToRefs(mainStore)
</script>

<template lang="pug">
.menu(
  @click="toggleMenu"
  :class="{ active: isMenuOpen }"
  )
  CloseIcon(v-if="isMenuOpen")
  Menu(v-else)
</template>

<style lang="scss" scoped>
.menu {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  @include tablet {
    width: get-vw(40, "md");
    height: get-vw(40, "md");
    background-size: get-vw(40, "md");
    margin: get-vw(15, "md") 0;
  }

  @include mobile {
    width: get-vw(32, "sm");
    height: get-vw(32, "sm");
    background-size: get-vw(32, "sm");
    margin: 0;
    margin-top: get-vw(2, "sm");

    svg {
      &:deep(path) {
        stroke-width: 2;
      }
    }
  }
}
</style>