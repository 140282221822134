<script setup lang="ts">
import Authorization from '@/components/Authorization.vue';
import { storeToRefs } from 'pinia';
const mainStore = useMainStore()
const {
  toggleSearch,
  toggleMenu,
  toggleProfile,
  closeProfile,
  toggleAuthForm,
  closeAuthForm,
} = mainStore
const {
  isSearchOpen,
  isProfileOpen,
  loggedIn,
  isAuthFormOpen,
  isMenuOpen
} = storeToRefs(mainStore)

const authorizationRef = ref<typeof Authorization | null>(null)

watch(() => isAuthFormOpen, (value) => {
  if (loggedIn) {
    return
  }
  if (value) {
    authorizationRef.open()
  } else {
    authorizationRef.close()
  }
})

watch(() => loggedIn, (value) => {
  if (value) {
    isAuthFormOpen && closeAuthForm()
  }
})
</script>

<template lang="pug">
.navbar-container
  .auth-container(v-if="!loggedIn" :class="{ 'auth-container--open': isAuthFormOpen, 'auth-container--hide': isAuthFormOpen === false }")
    .box
      Authorization.auth-screen.auth-screen--disabled(ref="authorizationRef")
  .navbar
    .top-bar
      navbar-hot-button
      navbar-logo(is-mobile)
      navbar-search.search
    .bottom-bar
      navbar-footer
      navbar-profile
      navbar-menu-button
      navbar-shop
      navbar-chat

  .menu-container(
    :class="{ 'active': isMenuOpen, 'hide': isMenuOpen === false }"
    ref="menuContainerRef"
  )
    ui-menu
</template>

<style lang="scss" scoped>
@include mobile {
$menu-duration: 0.17s;
.auth-container {
  width: 0vw;
  z-index: 31;
  background-color: $white;
  position: relative;
  overflow-x: hidden;
  height: 100%;

  @keyframes auth-show {
    from {
      width: 0vw;
    }
    to {
      width: 100vw;
    }
  }

  @keyframes auth-hide {
    from {
      width: 100vw;
    }
    to {
      width: 0vw;
    }
  }

  .box {
    width: 100%;
    position: absolute;
    left: -44vw;
    height: auto;
    padding-top: get-vw(40, "sm");
    padding-bottom: get-vw(40, "sm");

    @keyframes auth-box-show {
      from {
        left: -44vw;
      }
      to {
        left: 0;
      }
    }

    @keyframes auth-box-hide {
      from {
        left: 0;
      }
      to {
        left: -44vw;
      }
    }
  }
  .page {
    width: 100%;
    min-height: calc(90vh - (100vw * 0.2605));
  }

  &--open {
    animation-duration: 440ms;
    animation-name: auth-show;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
    height: 100dvh;
    .box {
      animation-duration: 440ms;
      animation-name: auth-box-show;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: linear;
    }
  }

  &--hide {
    animation-duration: 440ms;
    animation-name: auth-hide;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
    height: 100dvh;
    .box {
      animation-duration: 440ms;
      animation-name: auth-box-hide;
      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-timing-function: linear;
    }
  }
}

.menu-container {
  position: fixed;
  left: get-vw(-500, "sm");
  height: calc(100dvh - get-vw(138, "sm"));
  top: 0;
  width: 100vw;
  padding-top: get-vw(69, "sm");
  padding-bottom: get-vw(69, "sm");
  background-color: #FDFAF8;
  z-index: 11;

  @keyframes menu-show {
    from {
      left: -100vw;
    }
    to {
      left: 0;
    }
  }

  @keyframes menu-hide {
    from {
      left: 0;
    }
    to {
      left: -100vw;
    }
  }

  &.active {
    animation-duration: $menu-duration;
    animation-name: menu-show;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
    overflow-y: scroll;
  }

  &.hide {
    animation-duration: $menu-duration;
    animation-name: menu-hide;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-timing-function: linear;
  }
}

.navbar-container {
  position: relative;
  width: 100vw;
  height: 100dvh;
  background-color: $light-grey;
  top: 0;
  left: 0;
}

.navbar {
  width: 0;
}

.top-bar {
  position: fixed;
  left: 0;
  z-index: 50;
  top: 0;
  display: flex;
  align-items: center; 
  width: 100%;
  height: get-vw(40, "sm");
  background-color: $white;
  @include new-shadow;
  justify-content: space-between;
}

.bottom-bar {
  position: fixed;
  left: 0;
  z-index: 50;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: get-vw(40, "sm");
  background-color: $white;
  @include new-shadow;
}
}
</style>
