<script setup lang="ts">
import Auth from '@/assets/svg/auth.svg'
import Close from '@/assets/svg/icon-x.svg'
import ProfileAvatar from '/images/avatar.png'

const mainStore = useMainStore()
const { toggleAuthForm } = mainStore
const { isAuthFormOpen, loggedIn } = storeToRefs(mainStore)
</script>

<template lang="pug">
.profile-button
  nuxt-link.auth.profile-link(v-if="loggedIn" to="/profile/favorites" :class="{ loggedIn }")
    img(:src="ProfileAvatar" alt="Profile Avatar")
  .auth(v-else @click="toggleAuthForm" :class="{ loggedIn, active: isAuthFormOpen }")
    Auth(v-if="!isAuthFormOpen")
    Close(v-else)
</template>

<style lang="scss" scoped>
.profile-link {
  display: block;
}
.auth {
  width: 100%;
  /* height: get-vw(56); */
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  cursor: pointer;
}

img {
  width: 100%;
}

.close-profile {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0 auto get-vw(30);
  height: get-vw(56);
  width: get-vw(56);

  svg {
    height: get-vw(32);
    width: get-vw(32);

    &:deep(path) {
      fill: $black;
    }
  }
}

@include tablet {
  .auth {
    width: get-vw(40, "md");
    height: get-vw(40, "md");
    margin-top: get-vw(20, "md");
    margin-bottom: get-vw(35, "md");
  }

  .close-profile {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0 auto get-vw(15, "md");
    height: get-vw(40, "md");
    width: get-vw(40, "md");

    svg {
      height: get-vw(21, "md");
      width: get-vw(21, "md");

      path {
        fill: $black;
      }
    }
  }

  img {
    width: get-vw(40, "md");
    height: get-vw(40, "md");
  }
}

@include mobile {
  svg {
    &:deep(path) {
      stroke-width: 2;
    }
  }

  .auth {
    width: get-vw(32, "sm");
    height: get-vw(32, "sm");
    margin: 0;
  }

  img {
    width: get-vw(28, "sm");
    height: get-vw(28, "sm");
  }

  .profile-button {
    height: get-vw(32, "sm");
    width: get-vw(32, "sm");
  }

  .close-profile {
    height: get-vw(32, "sm");
    width: get-vw(32, "sm");
    margin-bottom: 0;

    svg {
      margin-top: get-vw(3, "sm");
      height: get-vw(18, "sm");
      width: get-vw(18, "sm");

      &:deep(path) {
        fill: $black;
      }
    }
  }
}
</style>