<template lang="pug">
div.logo-container
  template(v-if="props.isMobile")
    LogoMobile.logo(@click="$router.push({ name: 'index' })")
  template(v-else)
    Logo.logo(@click="$router.push({ name: 'index' })")
</template>

<script setup lang="ts">
import Logo from '@/assets/svg/logo.svg'
import LogoMobile from '@/assets/svg/logoMobile.svg'

interface Props {
  isMobile?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isMobile: false
})
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
}
.logo {
  width: 39%;
  margin: 0 auto;
  cursor: pointer;

  @include tablet {
    width: get-vw(16, "md");
    height: get-vw(220, "md");
  }
  @include mobile {
    position: relative;
    width: get-vw(166, "sm");
    height: get-vw(16, "sm");
    left: get-vw(-1, "sm");
    top: get-vw(2, "sm");
  }
}
</style>
