import { defineStore } from "pinia";

interface State {
  isAuth: boolean;
  accessToken: string;
  refreshToken?: string;
  isRegistering: boolean;
  errorMessage?: string;
  isLoading?: boolean;
}
export const useAuthStore = defineStore('auth', {
  state: (): State => {
    const { status, data } = useAuth()

    if (status.value === 'authenticated') {
      const { accessToken } = (data.value as any)

      const accessTokenCookie = useCookie<string>('token')
      // const refreshTokenCookie = useCookie<string>('refreshToken')
      accessTokenCookie.value = accessToken.value
      // refreshTokenCookie.value = refreshToken.value
      return {
        isAuth: true,
        accessToken: accessToken.value,
        isRegistering: false,
        // refreshToken: refreshToken.value,
      }
    }
    const accessToken = useCookie<string>('token')
    const refreshToken = useCookie<string>('refreshToken')
    // !accessToken.value && (accessToken.value = '')
    // !refreshToken.value && (refreshToken.value = '')
    return {
      isAuth: false,
      accessToken: accessToken.value,
      refreshToken: refreshToken.value,
      isRegistering: true,
      errorMessage: '',
      isLoading: false
    }
  },
  actions: {
    setIsAuth(isAuth: boolean) {
      this.isAuth = isAuth;
    },
    setIsRegistering(isRegistering: boolean) {
      this.isRegistering = isRegistering;
    },
    setAccessToken(token: string) {
      this.accessToken = token;
      const accessTokenCookie = useCookie('token')
      accessTokenCookie.value = token
    },
    setRefreshToken(token: string) {
      this.refreshToken = token;
      const refreshTokenCookie = useCookie('refreshToken')
      refreshTokenCookie.value = token
    },
    updateStore() {
      const { status, data } = useAuth()
      if (status.value === 'authenticated') {
        const { accessToken } = (data.value as any)
        this.isAuth = true;
        this.accessToken = accessToken.value;
        const accessTokenCookie = useCookie('token')
        accessTokenCookie.value = accessToken.value
      }
    },
    setMaximumSMSError() {
      this.errorMessage = 'Максимальное число отправленных СМС'
    },
    setInvalidNumberError() {
      this.errorMessage = 'Неправильный номер телефона'
    },
    resetNumberError() {
      this.errorMessage = ''
    },
    toggleLoading() {
      this.isLoading = !this.isLoading
    }
  },
});