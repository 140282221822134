export const useOAuth = () => {
  return {
    login: (provider: string) => {
      const mutation = gql`
        mutation ($provider: String!, $redirect_uri: String) {
          login(input: {provider: $provider, redirect_uri: $redirect_uri}) {
            redirect_uri
          }
        }
      `

      const variables = {
        provider,
        redirect_uri: window.location.origin + '/oauth/callback'
      }

      return new Promise<string>((resolve, reject) => {
        const request = useMutation(mutation, { variables })
        request.onDone((result) => {
          const { redirect_uri: redirectUri } = result.data.login
          navigateTo(redirectUri, { external: true })
          resolve(redirectUri)
        })
        request.onError((error) => {
          console.error(error)
          reject(error)
        })

        request.mutate()
      })
    },
    loginConfirmation: (code: string) => {
      const { signIn } = useAuth()
      return signIn('code', { code })
    }
  }
}