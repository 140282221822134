<template lang="pug">
.search-btn
  nuxt-link.search-link(to="/search" v-if="currentRoute !== '/search' || isMenuOpen" @click="mainStore.closeMenu")
    Search
  .close-search(v-else @click="closeSearch")
    CrossIcon
</template>


<script setup lang="ts">
import Search from '@/assets/svg/search.svg'
import { storeToRefs } from 'pinia'
import CrossIcon from '@/assets/svg/cross.svg'
const mainStore = useMainStore()
const {
  isSearchOpen,
  previousPage,
  isMenuOpen
} = storeToRefs(mainStore)

const router = useRouter()
const currentRoute = computed(() => router.currentRoute.value.path)

router.beforeEach((to, from, next) => {
  if (from.path !== '/search') {
    mainStore.setPreviousPage(from.path)
  }
  next()
})

const closeSearch = () => {
  if (previousPage?.value) {
    router.push(previousPage.value)
  } else {
    router.push('/')
  }
}
</script>

<style lang="scss" scoped>
.search-btn {
  position: relative;
  width: 100%;
  margin-top: get-vw(43);
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  @include tablet {
    width: get-vw(40, "md");
    height: get-vw(40, "md");
    background-size: get-vw(25, "md");
    /* padding-top: get-vw(10, "md"); */
    margin-bottom: get-vw(30, "md");
  }

  @include mobile {
    width: get-vw(32, "sm");
    height: get-vw(32, "sm");
    background-size: get-vw(32, "sm");
    padding-top: 0;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: get-vw(14, "sm");
    margin-left: 0;
  }
}
.close-search,
.search-link {
  position: absolute;
  top:      0;
  left:     0;
  bottom:   0;
  right:    0;

  @include mobile {
    svg {
      &:deep(path) {
        stroke-width: 2;
      }
    }
  }
}

.close-search {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  width: 100%;
  svg {
    cursor: pointer;
    height: get-vw(36);
    width: get-vw(36);

    &:deep(path) {
      fill: $black;
    }
  }
}

@include mobile {
  .close-search {
    width: get-vw(32, "sm");
    height: get-vw(32, "sm");
    margin-bottom: get-vw(-1, "sm");
    margin-right: get-vw(14, "sm");
    margin-left: 0;
  }

  .close-search svg {
    width: get-vw(16, "sm");
    height: get-vw(16, "sm");
  }
}
</style>